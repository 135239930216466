var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "discussion-group-mobile",
      class: { "discussion-group-mobile--landscape": _vm.isLandScape },
    },
    [
      _c("TeamTag", {
        staticClass: "discussion-group-mobile__team-tag",
        attrs: { team: _vm.team },
      }),
      _c(
        "div",
        { staticClass: "discussion-group-mobile__users" },
        _vm._l(_vm.users, function (user) {
          return _c(
            "div",
            { key: user.id, staticClass: "discussion-group-mobile__user" },
            [
              _c("AppUserCard", { attrs: { user: user } }),
              _c(
                "DiscussionGroupLabel",
                {
                  staticClass: "discussion-group-mobile__label",
                  attrs: { color: user.color },
                },
                [_vm._v(" " + _vm._s(user.label) + " ")]
              ),
            ],
            1
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }