

























import { defineComponent } from "@vue/composition-api"

import TeamTag from "@/components/GroupTeams/Common/TeamTag.vue"
import AppUserCard from "@/components/UserCard/AppUserCard.vue"

import DiscussionGroupLabel from "./DiscussionGroupLabel.vue"

import useDiscussionGroup from "./useDiscussionGroup"

export default defineComponent({
  name: "DiscussionGroupMobile",
  components: {
    DiscussionGroupLabel,
    TeamTag,
    AppUserCard
  },
  props: {
    isLandScape: {
      type: Boolean,
      default: undefined
    }
  },
  setup() {
    const { team, users } = useDiscussionGroup()

    return {
      team,
      users
    }
  }
})
